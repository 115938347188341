.galleryGrid {
    display: grid;
    grid-template: repeat(2, 1fr) / repeat(3, 1fr);
    gap: 3.57vw 2.56vw;
}

.bookGalleryGrid {
    display: grid;
    grid-template: repeat(2, 1fr) / repeat(3, 1fr);
    gap: 0vw 2.86vw;
}

@media only screen and (max-width: 992px) {
    .galleryGrid {
        grid-template: repeat(3, 1fr) / repeat(2, 1fr);
        gap: 4.28vw 3.07vw;
    }

    .bookGalleryGrid {
        display: grid;
        grid-template: repeat(3, 1fr) / repeat(2, 1fr);
        gap: 0vw 3.43vw;
    }
}

@media only screen and (max-width: 576px) {
    .galleryGrid {
        grid-template: repeat(6, 1fr) / repeat(1, 1fr);
        gap: 9.42vw 5.63vw;
    }

    .bookGalleryGrid {
        display: grid;
        grid-template: repeat(6, 1fr) / repeat(1, 1fr);
        gap: 0vw 7.55vw;
    }
}
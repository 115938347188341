.preFootHolder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5.71vw;
    gap: 5.71vw;
    background-color: var(--main-black);
    color: var(--main-white);
    border-radius: 0.71vw;
}

.preFootImageBox {
    width: 60%;
}

.preFootImage {
    width: 100%;
    border-radius: 0.71vw;
}

.preFootTextBox {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 1.43vw;
}

.preFootTag {
    font-size: var(--reg-p-16-desktop);
    font-weight: 300;
}

.preFootTitle {
    font-size: var(--h3-30-desktop);
}

.preFootText {
    font-size: var(--reg-p-16-desktop);
    font-weight: 400;
    line-height: 1.7;
}

@media only screen and (max-width: 992px) {
    .preFootHolder {
        padding: 6.85vw;
        gap: 6.85vw;
        border-radius: 0.85vw;
    }

    .preFootImageBox {
        width: 50%;
    }
    
    .preFootTextBox {
        width: 50%;
        gap: 1.72vw;
    }
    
    .preFootTag {
        font-size: var(--reg-p-16-tablet);
    }
    
    .preFootTitle {
        font-size: var(--h3-30-tablet);
    }
    
    .preFootText {
        font-size: var(--reg-p-16-tablet);
    }
}

@media only screen and (max-width: 576px) {
    .preFootHolder {
        padding: 7.85vw 6.85vw;
        border-radius: 1.87vw;
    }
    
    .preFootImageBox {
        display: none;
    }

    .preFootTextBox {
        width: 100%;
        gap: 4.73vw;
    }
    
    .preFootTag {
        font-size: var(--reg-p-16-mobile);
    }
    
    .preFootTitle {
        font-size: var(--h3-30-mobile);
    }
    
    .preFootText {
        font-size: var(--reg-p-16-mobile);
    }
}
.toolLabelWrapper {
    padding: 0.86vw 1.79vw;
    border: solid 0.11vw #22222250;
    border-radius: 50vw;
    display: inline-block;
}

.toolLabelText {
    font-size: var(--reg-p-16-desktop);
    font-weight: 600;
}

@media only screen and (max-width: 992px) {
    .toolLabelWrapper {
        padding: 1.03vw 2.15vw;
        border: solid 0.13vw #22222250;
    }
    
    .toolLabelText {
        font-size: var(--reg-p-18¡6-tablet);
    }
}

@media only screen and (max-width: 576px) {
    .toolLabelWrapper {
        padding: 2.27vw 4.73vw;
        border: solid 0.27vw #22222250;
    }
    
    .toolLabelText {
        font-size: var(--reg-p-16-mobile);
    }
}
/*  --  FONTS  --  */

@font-face {
    font-family: 'Hanken Grotesk';
    font-style: normal;
    font-weight: 300, 400, 500, 600, 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/hankengrotesk/v8/ieVn2YZDLWuGJpnzaiwFXS9tYtpd59A.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


/*  --  GLOBAL VARIABLES  --  */

:root {
  /*  colors  */
  --main-white: #f5f5f5;
  --main-black: #222222;
  --main-yellow: #fed502;
  /*  font size  */
  --reg-p-14-desktop: 1vw;
  --reg-p-14-tablet: 1.2vw;
  --reg-p-14-mobile: 3.04vw;
  --reg-p-16-desktop: 1.14vw;
  --reg-p-16-tablet: 1.37vw;
  --reg-p-16-mobile: 3.5vw;
  --reg-p-20-desktop: 1.43vw;
  --reg-p-20-tablet: 1.72vw;
  --reg-p-20-mobile: 3.98vw;
  --h3-30-desktop: 2.14vw;
  --h3-30-tablet: 2.57vw;
  --h3-30-mobile: 5.65vw;
  --h2-45-desktop: 3.21vw;
  --h2-45-tablet: 3.85vw;
  --h2-45-mobile: 6.93vw;
  --h1-80-desktop: 5.72vw;
  --h1-80-tablet: 6.86vw;
  --h1-80-mobile: 13.15vw;
  /*  margins  */
  --lr-margin-desktop: 3.57vw;
  --lr-margin-tablet: 2.98vw;
  --lr-margin-mobile: 3vw;
  --tb-margin-desktop: 7.86vw;
  --tb-margin-tablet: 9.4vw;
  --tb-margin-mobile: 20.88vw;
}

/*  -- RESET & GLOBAL STYLES  --  */

*, *::before, *::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  background-color: var(--main-white);
  font-family: 'Hanken Grotesk', sans-serif;
  color: var(--main-black);
  line-height: 1.25;
  letter-spacing: 0.01vw;
  font-size: var(--reg-p-16-desktop);
}

section {
  padding: var(--tb-margin-desktop) var(--lr-margin-desktop)
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

h1 {
  font-size: var(--h1-80-desktop);
  font-weight: 700;
  line-height: 1.2;
}

h2 {
  font-size: var(--h2-45-desktop);
  font-weight: 700;
  line-height: 1.1;
}

.h3-card-title-22 {
  font-size: 1.57vw;
  font-weight: 700;
  line-height: 1.1;
}

.h3-card-title-18 {
  font-size: 1.29vw;
  font-weight: 700;
}

a {
  cursor: pointer;
  color: var(--main-black);
  text-decoration-line: none;
  position: relative;
}

#root, #__next {
  isolation: isolate;
}

.nav-logo {
  height: 1.43vw;
  width: auto;
}

.bold-tag {
  font-weight: 700;
  margin: 2.14vw 0;
}

/* CSS for the links with the arrow */
.link-group-holder, .link-group-holder-hero {
  display: flex;
  gap: 1.79vw;
  align-items: center;
  margin-top: 0.71vw;
}

.link-group-arrow {
  width: 1.79vw;
  height: auto;
}

.link-group-wrapper {
  display: flex;
  align-items: center;
}

/* CSS for the centered link holder */

.centered-link-holder {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 2.56vw;
}

/* CSS for the text loop component */
.text-loop-track, .text-loop-holder {
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  flex-wrap: nowrap;
}

.text-loop-holder {
  animation: text-loop 15000ms linear infinite;
}

.hero-loop-header-bold {
  font-weight: 700;
  font-size: var(--h3-30-desktop);
  white-space: nowrap;
}

.hero-loop-header-light {
  font-weight: 400;
  font-size: var(--h3-30-desktop);
  white-space: nowrap;
}

@keyframes text-loop {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media only screen and (max-width: 992px) {
  body {
    letter-spacing: 0.012vw;
    font-size: var(--reg-p-16-tablet);
  }

  section {
    padding: var(--tb-margin-tablet) var(--lr-margin-tablet)
  }

  h1 {
    font-size: var(--h1-80-tablet);
  }

  h2 {
    font-size: var(--h2-45-tablet);
    line-height: 1.2;
  }

  .h3-card-title-22 {
    font-size: 1.88vw;
  }

  .h3-card-title-18 {
    font-size: 1.55vw;
  }

  .nav-logo {
    height: 1.72vw;
  }

  .bold-tag {
    margin: 2.57vw 0;
  }

  /* CSS for the links with the arrow */
  .link-group-holder, .link-group-holder-hero {
    gap: 2.15vw;
    margin-top: 0.85vw;
  }

  .link-group-arrow {
    width: 2.15vw;
  }

  /* CSS for the centered link holder */

  .centered-link-holder {
    padding-top: 3.07vw;
  }

  /* CSS for the text loop component */
  .hero-loop-header-bold {
    font-size: var(--h3-30-tablet);
  }

  .hero-loop-header-light {
    font-size: var(--h3-30-tablet);
  }
}

@media only screen and (max-width: 576px) {
  body {
    letter-spacing: 0.026vw;
    font-size: var(--reg-p-16-mobile);
  }

  section {
    padding: var(--tb-margin-mobile) var(--lr-margin-mobile)
  }

  h1 {
    font-size: var(--h1-80-mobile);
  }

  h2 {
    font-size: var(--h2-45-mobile);
    line-height: 1.2;
  }

  .h3-card-title-22 {
    font-size: 4.21vw;
  }

  .h3-card-title-18 {
    font-size: 3.82vw;
  }

  .nav-logo {
    height: 4.4vw;
  }

  .bold-tag {
    margin: 5.65vw 0;
  }

  /* CSS for the links with the arrow */
  .link-group-holder {
    gap: 3.87vw;
    margin-top: 1.87vw;
    flex-wrap: wrap;
    justify-content: center;
    width: 80%;
  }

  .link-group-holder-hero {
    gap: 3.87vw;
    margin-top: 1.87vw;
  }

  .link-group-tag {
    text-align: center;
  }

  .link-group-arrow {
    width: 4.73vw;
  }

  /* CSS for the centered link holder */

  .centered-link-holder {
    padding-top: 6.75vw;
  }

  /* CSS for the text loop component */
  .hero-loop-header-bold {
    font-size: var(--h3-30-mobile);
  }

  .hero-loop-header-light {
    font-size: var(--h3-30-mobile);
  }
}
.heroContainer {
    padding: 1.43vw var(--lr-margin-desktop) var(--tb-margin-desktop);
}

.heroHolder {
    display: flex;
    justify-content: space-between;
    gap: 6.43vw;
}

.heroTextBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 50%;
    gap: 2.14vw;
    overflow: hidden;
}

.heroText {
    font-size: var(--reg-p-20-desktop);
    line-height: 1.6;
}

.heroImageBox {
    width: 50%;
    height: 35.71vw;
    border-radius: 1.43vw;
    display: flex;
    justify-content: center;
    align-content: center;
    overflow: hidden;
}

@media only screen and (max-width: 992px) {
    .heroContainer {
        padding: 1.72vw var(--lr-margin-tablet) var(--tb-margin-tablet);
    }
    
    .heroHolder {
        gap: 5.36vw;
    }
    
    .heroTextBox {
        gap: 2.57vw;
    }
    
    .heroText {
        font-size: var(--reg-p-20-tablet);
    }
    
    .heroImageBox {
        height: 42.85vw;
        border-radius: 1.72vw;
    }
}

@media only screen and (max-width: 576px) {
    .heroContainer {
        padding: 8.32vw var(--lr-margin-mobile) var(--tb-margin-mobile);
    }
    
    .heroHolder {
        flex-direction: column;
        gap: 11.8vw;
    }
    
    .heroTextBox {
        width: 100%;
        gap: 5.65vw;
    }
    
    .heroText {
        font-size: var(--reg-p-20-mobile);
    }
    
    .heroImageBox {
        width: 100%;
        height: 76.14vw;
        border-radius: 3.78vw;
    }
}
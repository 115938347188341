.bigPhrase {
    font-size: 3vw;
    font-weight: 300;
    line-height: 1.3;
}

@media only screen and (max-width: 992px) {
    .bigPhrase {
        font-size: 3.6vw;
        line-height: 1.35;
    }
}

@media only screen and (max-width: 576px) {
    .bigPhrase {
        font-size: 7vw;
        line-height: 1.45;
    }
}
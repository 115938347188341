.navLinkHolder {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}

.navLinkText {
    font-weight: 400;
}

.navLinkUnderlineTrack {
    height: 0.05vw;
    background-color: transparent;
}

.navLinkUnderline {
    width: 0%;
    height: 100%;
    background-color: var(--main-black);
    transition: width 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.navLinkHolder:hover .navLinkUnderline {
    width: 100%;
}

@media only screen and (max-width: 992px) {
    .navLinkUnderlineTrack {
        height: 0.06vw;
    }
}

@media only screen and (max-width: 992px) {
    .navLinkUnderlineTrack {
        height: 0.13vw;
    }
}
.footer {
    padding: 5.71vw var(--lr-margin-desktop) 2.56vw;
}

.footerContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    gap: 5.71vw;
}

.pagesNavBox {
    display: flex;
    flex-direction: column;
    gap: 3.57vw;
}

/*.logoLinkBlock {}*/

.footLinksHolder {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.footLinkWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.43vw;
}

.footLinkCategory, .footNote {
    font-weight: 700;
    font-size: var(--reg-p-16-desktop);
}

.contactCard {
    display: flex;
    flex-direction: column;
    gap: 0.86vw;
    background-color: #cccccc;
    padding: 1.79vw;
    border-radius: 0.6vw;
}

.cardLinkBox {
    display: flex;
    align-items: center;
    gap: 0.71vw;
}

.cardLinkText {
    font-weight: 300;
}

.cardLinkImage {
    height: 1.07vw;
    width: auto;
}

.conectNavBox, .conectLinkWrap {
    display: flex;
    justify-content: space-between;
    gap: 2.56vw;
}

.conectNavBox {
    padding: 2.14vw 0vw;
    border-top: 0.05vw dashed #cccccc;
}

@media only screen and (max-width: 992px) {
    .footer {
        padding: 6.85vw var(--lr-margin-tablet) 3.07vw;
    }

    .footerContainer {
        gap: 6.85vw;
    }

    .pagesNavBox {
        gap: 4.28vw;
    }
    
    .footLinkWrap {
        gap: 1.72vw;
    }
    
    .footLinkCategory, .footNote {
        font-size: var(--reg-p-16-tablet);
    }
    
    .contactCard {
        gap: 1.03vw;
        padding: 2.15vw;
        border-radius: 0.72vw;
    }
    
    .cardLinkBox {
        gap: 0.85vw;
    }
    
    .cardLinkImage {
        height: 1.28vw;
    }
    
    .conectNavBox, .conectLinkWrap {
        gap: 3.07vw;
    }
    
    .conectNavBox {
        padding: 2.57vw 0vw;
        border-top: 0.06vw dashed #cccccc;
    }
}

@media only screen and (max-width: 576px) {
    .footer {
        padding: 15.07vw var(--lr-margin-mobile) 6.75vw;
    }
    
    .footerContainer {
        gap: 15.07vw;
    }
    
    .pagesNavBox {
        gap: 9.42vw;
    }
    
    /*.logoLinkBlock {}*/
    
    .footLinksHolder {
        flex-wrap: wrap;
        row-gap: 9.42vw;
        column-gap: 13.07vw;
        justify-content: flex-start;
    }
    
    .footLinkWrap {
        width: 38vw;
        gap: 3.78vw;
    }
    
    .footLinkCategory, .footNote {
        font-size: var(--reg-p-16-mobile);
    }
    
    .contactCard {
        gap: 2.57vw;
        padding: 5.13vw;
        border-radius: 1.58vw;
    }
    
    .cardLinkBox {
        gap: 1.87vw;
    }
    
    .cardLinkImage {
        height: 3.02vw;
    }
    
    .conectNavBox {
        flex-direction: column;
    }

    .conectNavBox, .conectLinkWrap {
        gap: 5.75vw;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    
    .conectNavBox {
        padding: 5.65vw 0vw;
        border-top: 0.13vw dashed #cccccc;
    }
}



/* CSS FOR THE CURRICULUM LINK */
.navLinkHolder {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}

.navLinkText {
    font-weight: 400;
}

.navLinkUnderlineTrack {
    height: 0.05vw;
    background-color: transparent;
}

.navLinkUnderline {
    width: 0%;
    height: 100%;
    background-color: var(--main-black);
    transition: width 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.navLinkHolder:hover .navLinkUnderline {
    width: 100%;
}

@media only screen and (max-width: 992px) {
    .navLinkUnderlineTrack {
        height: 0.06vw;
    }
}

@media only screen and (max-width: 992px) {
    .navLinkUnderlineTrack {
        height: 0.13vw;
    }
}
.displayHolder {
    display: flex;
    flex-direction: column;
    gap: 3.57vw;
}

.displayContent {
    display: flex;
    gap: 3.57vw;                                 
}

.displayDirectory {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.displayCarousel {
    display: flex;
    flex-direction: column;
    width: 50%;
}

/* */
.carouselContainer {
    border-top: 0.035vw solid #cccccc80;
    padding: 1.79vw 0;
    display: flex;
    gap: 2.14vw;
}

.projectCardHolder {
    width: 20.71vw;
}

.carouselContainerBooks {
    border-top: 0.035vw solid #cccccc80;
    padding: 1.79vw 0;
    display: grid;
    grid-template: repeat(1, 1fr) / repeat(4, 1fr);
    gap: 2.14vw;
}

.projectCardHolderBooks {
    width: auto;
}
/*  */

/* STARTS MAIN WORK CARD STYLES */

.managerCard {
    display: flex;
    width: 100%;
    flex-direction: column;
    border-radius: 0.71vw;
    overflow: hidden;
}

.cardImageHolder {
    height: 14.29vw;
    position: relative;
}

.cardImageOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #22222233;
    z-index: 2;
    opacity: 0;
    transition: opacity 500ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.cardImageOverlay:hover {
    opacity: 1;
}

.cardInfoHolder {
    display: flex;
    flex-direction: column;
    background-color: white;
}

/* Starts DirectoryCard */
.directoryCard {
    display: flex;
    align-items: center;
    padding: 1.79vw 1.79vw;
    gap: 1.43vw;
    border-top: 0.035vw solid #cccccc80;
}

.dirCardCircle {
    width: 4.29vw;
    height: 4.29vw;
    border-radius: 100%;
    overflow: hidden;
    opacity: 1;
    transition: opacity 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.dirCardCircle:hover {
    opacity: 0.75;
}

.dirCardInfoBox {
    display: flex;
    flex-direction: column;
    gap: 0.36vw;
    flex-grow: 1;
}

.dirCardTextWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dirCardText {
    font-weight: 300;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
/* Ends DirectoryCard */

.cardLinksWrapper {
    display: flex;
    justify-content: space-between;
    padding: 1.43vw 1.79vw;
    border-top: 0.035vw solid #cccccc80;
}

.categoryBox {
    display: flex;
    align-items: center;
    gap: 0.43vw;
}

.icon {
    width: 1.33vw;
    height: 1.33vw;
}

.categoryTag {
    font-size: var(--reg-p-14-desktop);
    font-weight: 500;
}

.linkGroupHolder {
    display: flex;
    gap: 1.07vw;
    align-items: center;
}

.linkGroupTag {
    font-weight: 300;
}

.linkGroupArrow {
    width: 1.43vw;
    height: auto;
}

/* ENDS MAIN WORK CARD STYLES */

@media only screen and (max-width: 992px) {
    .displayHolder {
        gap: 4.28vw;
    }

    .displayContent {
        gap: 2.98vw;                                 
    }

    .carouselContainer {
        border-top: 0.042vw solid #cccccc80;
        padding: 2.15vw 0;
        gap: 1.78vw;
    }

    .projectCardHolder {
        width: 24.85vw;
    }

    .carouselContainerBooks {
        border-top: 0.042vw solid #cccccc80;
        padding: 2.15vw 0;
        display: grid;
        grid-template: repeat(1, 1fr) / repeat(4, 1fr);
        gap: 1.78vw;
    }

    /* STARTS MAIN WORK CARD STYLES */

    .managerCard {
        border-radius: 0.85vw;
    }

    .cardImageHolder {
        height: 17.15vw;
    }

    /* Starts DirectoryCard */
    .directoryCard {
        padding: 2.15vw 2.15vw;
        gap: 1.19vw;
        border-top: 0.042vw solid #cccccc80;
    }

    .dirCardCircle {
        width: 5.15vw;
        height: 5.15vw;
    }

    .dirCardInfoBox {
        gap: 0.43vw;
    }

    .dirCardTextWrap {
        position: relative;
    }
    /* Ends DirectoryCard */

    .cardLinksWrapper {
        padding: 1.72vw;
        border-top: 0.042vw solid #cccccc80;
    }

    .categoryBox {
        gap: 0.52vw;
    }

    .icon {
        width: 1.59vw;
        height: 1.59vw;
    }

    .categoryTag {
        font-size: var(--reg-p-14-tablet);
    }

    .linkGroupHolder {
        gap: 1.28vw;
    }

    .linkGroupArrow {
        width: 1.72vw;
    }

    /* ENDS MAIN WORK CARD STYLES */
}

@media only screen and (max-width: 576px) {
    .displayHolder {
        gap: 9.42vw;
    }

    .displayContent {
        flex-direction: column;
        gap: 6.56vw;                                 
    }
    
    .displayDirectory {
        width: 100%;
    }
    
    .displayCarousel {
        width: 100%;
    }

    /* */
    .carouselContainer {
        border-top: 0.092vw solid #cccccc80;
        padding: 4.73vw 0;
        flex-direction: column;
        gap: 9.65vw;
    }
    
    .projectCardHolder {
        width: 100%;
    }

    .carouselContainerBooks {
        border-top: 0.092vw solid #cccccc80;
        padding: 4.73vw 0;
        display: grid;
        grid-template: repeat(2, 1fr) / repeat(2, 1fr);
        gap: 9.65vw 6.65vw;
    }

    .projectCardHolder {
        width: 100%;
    }
    /*  */

    /* STARTS MAIN WORK CARD STYLES */

    .managerCard {
        border-radius: 1.87vw;
    }

    .cardImageHolder {
        height: 49.28vw;
    }

    /* Starts DirectoryCard */
    .directoryCard {
        padding: 4.73vw 3.73vw;
        gap: 3.36vw;
        border-top: 0.092vw solid #cccccc80;
    }

    .dirCardCircle {
        min-width: 11.33vw;
        height: 11.33vw;
    }

    .dirCardInfoBox {
        gap: 1.13vw;
        overflow: hidden;
    }
    /* Ends DirectoryCard */

    .cardLinksWrapper {
        padding: 3.78vw;
        border-top: 0.092vw solid #cccccc80;
    }

    .categoryBox {
        gap: 1.14vw;
    }

    .icon {
        width: 3.5vw;
        height: 3.5vw;
    }

    .categoryTag {
        font-size: var(--reg-p-14-mobile);
    }

    .linkGroupHolder {
        gap: 2.82vw;
    }

    .linkGroupArrow {
        width: 3.98vw;
    }

    /* ENDS MAIN WORK CARD STYLES */
}
.optionsBar {
    padding: 0.71vw var(--lr-margin-desktop);
}

.optionsBarHolder {
    display: flex;
    justify-content: space-between;
}

.socialLinksBox {
    display: flex;
    gap: 0.71vw;
    align-items: center;
}

.optSocialLink, .optShareLink {
    position: relative;
    opacity: 1;
    transition: opacity 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.optSocialLink:hover, .optShareLink:hover {
    opacity: 0.5;
}

.optSocialLinkImage {
    height: 2.56vw;
    width: 2.56vw;
}

.shareLinksBox {
    display: flex;
    gap: 1.43vw;
    align-items: center;
}

.optShareLinkImage {
    height: 1.43vw;
    width: 1.43vw;
}

@media only screen and (max-width: 992px) {
    .optionsBar {
        padding: 0.85vw var(--lr-margin-tablet);
    }
    
    .socialLinksBox {
        gap: 0.85vw;
    }
    
    .optSocialLinkImage {
        height: 3.07vw;
        width: 3.07vw;
    }
    
    .shareLinksBox {
        gap: 1.72vw;
    }
    
    .optShareLinkImage {
        height: 1.72vw;
        width: 1.72vw;
    }
}

@media only screen and (max-width: 576px) {
    .optionsBar {
        padding: 1.87vw var(--lr-margin-mobile);
    }
    
    .socialLinksBox {
        gap: 1.87vw;
    }
    
    .optSocialLinkImage {
        height: 6.75vw;
        width: 6.75vw;
    }
    
    .shareLinksBox {
        gap: 3.78vw;
    }
    
    .optShareLinkImage {
        height: 3.78vw;
        width: 3.78vw;
    }
}
.projectsGallery{
    display: flex;
    flex-direction: column;
    gap: 5.71vw;
}

.goalsSection, .processSection, .examplesSection, .skillsSection, .statsSection {
    display: flex;
    flex-direction: column;
    gap: 3.57vw;
}

.goalsHolder {
    display: flex;
    gap: 5.71vw;
}

.goalsBox {
    width: 50%;
}

.goalsText {
    font-weight: 300;
    font-size: var(--reg-p-20-desktop);
    line-height: 1.6;
}

.examplesHolder {
    display: flex;
    gap: 3.57vw;
}

.exampleBox {
    width: 33.33%;
}

.exampleText {
    font-weight: 300;
    line-height: 1.55;
}

.toolsWrapper {
    padding-top: 2.14vw;
    border-top: dashed 0.07vw #22222250;
    display: flex;
    flex-wrap: wrap;
    gap: 1.43vw;
}

@media only screen and (max-width: 992px) {
    .profileCardSection {
        padding: var(--tb-margin-tablet) var(--lr-margin-tablet);
    }
    
    .profileCardHolder {
        padding: 4.76vw;
        gap: 4.76vw;
        border-radius: 1.72vw;
    }
    
    .titleWrapper {
        gap: 1.72vw;
        width: 30%;
    }

    .bioCardHeader {
        font-size: var(--h2-45-tablet);
    }
    
    .infoWrapper {
        gap: 2.15vw;
        padding-top: 3.43vw;
        width: 45%;
    }
    
    /* .detailsHolder, .detailsBox {
        gap: 1.28vw;
    }
    
    .icon {
        width: 1.72vw;
        height: 1.72vw;
    } */
    
    .photoWrapper {
        width: 24%;
        padding-top: 3.43vw;
    }
    
    .bioPhotoBox {
        width: 18.5vw;
        height: 18.5vw;
    }
    
    .sectionIntroWrapper {
        gap: 1.72vw;
    }

    .sectionHeader {
        width: 44vw;
    }
    
    .sectionInfo {
        width: 40vw;
    }
    
    .projectsGallery{
        gap: 6.85vw;
    }
    
    .goalsSection, .processSection, .examplesSection, .skillsSection, .statsSection {
        gap: 4.28vw;
    }

    .goalsHolder {
        display: flex;
        gap: 6.85vw;
    }
    
    .goalsBox {
        width: 50%;
    }
    
    .goalsText {
        font-weight: 300;
        font-size: var(--reg-p-20-tablet);
        line-height: 1.6;
    }
    
    .examplesHolder {
        gap: 4.28vw;
    }
    
    .toolsWrapper {
        padding-top: 2.57vw;
        border-top: dashed 0.08vw #22222250;
        gap: 1.72vw;
    }
}

@media only screen and (max-width: 576px) {
    .profileCardSection {
        padding: var(--tb-margin-mobile) var(--lr-margin-mobile);
    }
    
    .profileCardHolder {
        flex-direction: column;
        padding: 10vw 8vw;
        gap: 8vw;
        border-radius: 3vw;
    }
    
    .titleWrapper {
        gap: 3.78vw;
        width: auto;
    }
    
    .bioCardHeader {
        font-size: var(--h2-45-mobile);
    }
    
    .infoWrapper {
        gap: 4.73vw;
        width: auto;
        padding-top: 0;
    }
    
    /* .detailsHolder, .detailsBox {
        gap: 2.82vw;
    }
    
    .icon {
        width: 3.78vw;
        height: 3.78vw;
    } */
    
    .photoWrapper {
        width: auto;
    }
    
    .bioPhotoBox {
        width: 100%;
        height: 55vw;
        border-radius: 2.54vw;
    }
    
    .sectionIntroWrapper {
        gap: 3.78vw;
    }
    
    .sectionHeaderBox {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 9.46vw;
    }
    
    .sectionHeader {
        width: 90%;
    }
    
    .sectionInfo {
        width: 96%;
    }
    
    .projectsGallery{
        gap: 15.07vw;
    }
    
    .goalsSection, .processSection, .examplesSection, .skillsSection, .statsSection {
        gap: 9.42vw;
    }

    .goalsHolder {
        flex-direction: column;;
        gap: 6.85vw;
    }
    
    .goalsBox {
        width: 96%;
    }
    
    .goalsText {
        font-weight: 300;
        font-size: var(--reg-p-20-mobile);
        line-height: 1.6;
    }
    
    .examplesHolder {
        flex-direction: column;
        gap: 6.85vw;
    }
    
    .exampleBox {
        width: 95%;
    }
    
    .exampleText {
        line-height: 1.65;
    }
    
    .toolsWrapper {
        padding-top: 5.65vw;
        border-top: dashed 0.18vw #22222250;
        gap: 3.78vw;
    }
}
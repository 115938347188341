.linkHolder {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}

.linkText {
    font-weight: 600;
}

.linkUnderlineTrack {
    height: 0.09vw;
    background-color: #999999;
}

.linkUnderline {
    width: 0%;
    height: 100%;
    background-color: var(--main-black);
    transition: width 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.linkHolder:hover .linkUnderline {
    width: 100%;
}

@media only screen and (max-width: 992px) {
    .linkUnderlineTrack {
        height: 0.12vw;
    }
}

@media only screen and (max-width: 576px) {
    .linkUnderlineTrack {
        height: 0.24vw;
    }
}
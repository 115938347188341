.sectionIntroWrapper {
    display: flex;
    flex-direction: column;
    gap: 1.43vw;
}

.sectionTag {
    font-weight: 300;
}

.sectionHeaderBox {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.sectionHeader {
    width: 35.14vw;
}

.sectionInfo {
    width: 35.71vw;
    margin-bottom: 0.3vw;
    text-align: right;
    line-height: 1.35;
}

/* CENTERED */
.sectionIntroWrapperCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.79vw;
}

.sectionHeaderBoxCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1.79vw;
}

.introTag {
    font-weight: 300;
    text-align: center;
}

.introHeader {
    font-size: var(--h1-80-desktop);
    font-weight: 700;
    text-align: center;
}

.introInfo {
    text-align: center;
    width: 46.83vw;
    line-height: 1.5;
}

/* PROJECT */

.projectIntroHeader {
    text-transform: uppercase;
}

.projectInfoWrapper {
    display: flex;
    align-items: center;
    gap: 0.71vw;
}

.workCategory {
    font-size: var(--reg-p-20-desktop);
    font-weight: 600;
}

.clientWrapper {
    display: flex;
    align-items: center;
    gap: 0.38vw;
    font-size: var(--reg-p-20-desktop);
    font-weight: 600;
}

.clientImageBox {
    width: 2.14vw;
    height: 2.14vw;
    border-radius: 100%;
    overflow: hidden;
}

@media only screen and (max-width: 992px) {
    .sectionIntroWrapper{
        gap: 1.72vw;
    }
    
    .sectionHeader {
        width: 42.17vw;
    }
    
    .sectionInfo {
        width: 42.85vw;
        margin-bottom: 0.36vw;
    }

    /* CENTERED */
    .sectionIntroWrapperCenter {
        gap: 2.15vw;
    }

    .sectionHeaderBoxCenter {
        gap: 2.15vw;
    }

    .introHeader {
        font-size: var(--h1-80-tablet);
    }

    .introInfo {
        width: 56.83vw;
    }

    /* PROJECT */

    .projectInfoWrapper {
        gap: 0.85vw;
    }

    .workCategory {
        font-size: var(--reg-p-20-tablet);
    }

    .clientWrapper {
        gap: 0.47vw;
        font-size: var(--reg-p-20-tablet);
    }

    .clientImageBox {
        width: 2.57vw;
        height: 2.57vw;
    }
}

@media only screen and (max-width: 576px) {
    .sectionIntroWrapper{
        gap: 3.78vw;
    }
    
    .sectionHeaderBox {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        gap: 4.29vw;
    }
    
    .sectionHeader {
        width: 84.85vw;
        line-height: 1.21;
    }
    
    .sectionInfo {
        width: 95%;
        text-align: left;
        line-height: 1.6;
    }

    /* CENTERED */
    .sectionIntroWrapperCenter {
        gap: 4.73vw;
    }

    .sectionHeaderBoxCenter {
        gap: 4.73vw;
    }

    .introHeader {
        font-size: var(--h1-80-mobile);
    }

    .introInfo {
        width: 76.83vw;
        line-height: 1.6;
    }

    /* PROJECT */

    .projectInfoWrapper {
        gap: 1.87vw;
    }

    .workCategory {
        font-size: var(--reg-p-20-mobile);
    }

    .clientWrapper {
        gap: 1.03vw;
        font-size: var(--reg-p-20-mobile);
    }

    .clientImageBox {
        width: 5.65vw;
        height: 5.65vw;
    }
}
.notesSection {
    padding-top: 4.29vw;
    display: flex;
    flex-direction: column;
    gap: 1.43vw;
}

.notesIntroTag {
    font-weight: 300;
}

.notesContentHolder {
    display: flex;
    gap: 7.14vw;
}

.notesHeaderWrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4.29vw;
    width: 50%;
}

.notesTitleBox {
    display: flex;
    flex-direction: column;
    gap: 0.71vw;
}

.notesTitle {
    font-size: var(--h2-45-desktop);
}

.notesAuthor {
    font-size: 3vw;
    font-weight: 400;
}

.optionsHolder {
    display: flex;
    flex-direction: column;
    gap: 2.14vw;
}

.linkWrap {
    display: flex;
    align-items: center;
    gap: 1.07vw;
}

.blackArrowLeft {
    width: 1.79vw;
    height: auto;
    transform: rotate(180deg);
}

.linkHolder {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}

.linkText {
    font-weight: 600;
}

.linkUnderlineTrack {
    height: 0.09vw;
    background-color: #999999;
}

.linkUnderline {
    width: 0%;
    height: 100%;
    background-color: var(--main-black);
    transition: width 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.linkHolder:hover .linkUnderline {
    width: 100%;
}

.affiliateText {
    font-size: var(--reg-p-14-desktop);
    font-weight: 300;
}

.notesTextWrap {
    display: flex;
    flex-direction: column;
    gap: 1.79vw;
    width: 50%;
}

.notesP {
    font-size: var(--reg-p-20-desktop);
    font-weight: 300;
    line-height: 1.55;
}

.centeredLinkHolder {
    display: none;
}

@media only screen and (max-width: 992px) {
    .notesSection {
        padding-top: 5.15vw;
        gap: 1.72vw;
    }
    
    .notesContentHolder {
        gap: 5.95vw;
    }
    
    .notesHeaderWrap {
        gap: 5.15vw;
    }
    
    .notesTitleBox {
        gap: 0.85vw;
    }
    
    .notesTitle {
        font-size: var(--h2-45-tablet);
    }
    
    .notesAuthor {
        font-size: 3.6vw;
    }
    
    .optionsHolder {
        gap: 2.57vw;
    }
    
    .linkWrap {
        gap: 1.28vw;
    }
    
    .blackArrowLeft {
        width: 2.15vw;
    }
    
    .linkUnderlineTrack {
        height: 0.11vw;
    }
    
    .affiliateText {
        font-size: var(--reg-p-14-tablet);
    }

    .notesTextWrap {
        gap: 2.15vw;
    }
    
    .notesP {
        font-size: var(--reg-p-20-tablet);
    }
}

@media only screen and (max-width: 576px) {
    .notesSection {
        padding-top: 11.33vw;
        gap: 3.78vw;
    }
    
    .notesContentHolder {
        flex-direction: column;
        gap: 11.33vw;
    }
    
    .notesHeaderWrap {
        gap: 11.33vw;
        width: auto;
    }
    
    .notesTitleBox {
        gap: 1.87vw;
    }
    
    .notesTitle {
        font-size: var(--h2-45-mobile);
    }
    
    .notesAuthor {
        font-size: 6.48vw;
    }
    
    .optionsHolder {
        display: none;
    }
    
    .linkWrap {
        gap: 2.83vw;
    }
    
    .blackArrowLeft {
        width: 4.73vw;
    }
    
    .linkUnderlineTrack {
        height: 0.24vw;
    }

    .affiliateText {
        font-size: var(--reg-p-14-mobile);
    }
    
    .notesTextWrap {
        gap: 4.73vw;
        width: auto;
    }
    
    .notesP {
        font-size: var(--reg-p-20-mobile);
        line-height: 1.65;
    }

    .centeredLinkHolder {
        display: flex;
        flex-direction: column;
        gap: 3.87vw;
        padding-top: 9.43vw;
    }
}
.certCardHolder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: dashed 0.07vw #22222230;
    padding: 2.14vw;
    transition: background-color 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.certCardHolder:hover {
    background-color: #f1f1f1;
}

.cardTitlesHolder {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.71vw;
}

.certTitle {
    font-size: var(--reg-p-20-desktop);
    font-weight: 600;
}

.academyInfo {
    display: flex;
    gap: 0.38vw;
}

.academyImageBox {
    width: 2.14vw;
    height: 2.14vw;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.certInfoBox {
    width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.07vw;
    font-weight: 300;
}

.blackButton {
    background-color: var(--main-black);
    width: 11.43vw;
    padding: 0.86vw 0vw;
    color: var(--main-white);
    font-size: 0.86vw;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.43vw;
}

.openLink {
    border: solid 0.07vw var(--main-black);
    width: 11.43vw;
    padding: 0.86vw 0vw;
    font-size: 0.86vw;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.43vw;
    cursor: pointer;
    transition: background-color 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.openLink:hover {
    background-color: #f9f9f9;
}

@media only screen and (max-width: 992px) {
    .certCardHolder {
        border-top: dashed 0.08vw #22222230;
        padding: 2.57vw 1.78vw;
    }
    
    .cardTitlesHolder {
        width: 55%;
        gap: 0.85vw;
    }
    
    .certTitle {
        font-size: var(--reg-p-20-tablet);
    }
    
    .academyInfo {
        gap: 0.46vw;
    }
    
    .academyImageBox {
        width: 2.57vw;
        height: 2.57vw;
    }
    
    .certInfoBox {
        gap: 1.28vw;
    }
    
    .blackButton {
        width: 13vw;
        padding: 1.03vw 0vw;
        font-size: 1.03vw;
        border-radius: 0.52vw;
    }
    
    .openLink {
        border: solid 0.08vw var(--main-black);
        width: 13vw;
        padding: 1.03vw 0vw;
        font-size: 1.03vw;
        border-radius: 0.52vw;
    }
}

@media only screen and (max-width: 576px) {
    .certCardHolder {
        border-top: dashed 0.18vw #22222230;
        padding: 6vw 0vw;
    }
    
    .cardTitlesHolder {
        width: auto;
        gap: 1.87vw;
        flex-wrap: wrap;
    }
    
    .certTitle {
        font-size: var(--reg-p-20-mobile);
        width: 100%;
    }
    
    .academyInfo {
        gap: 1.01vw;
    }
    
    .academyImageBox {
        width: 5.65vw;
        height: 5.65vw;
    }
    
    .certInfoBox {
        display: none;
    }
    
    .blackButton {
        width: 20vw;
        padding: 3vw 0vw;
        font-size: 2.27vw;
        border-radius: 1.14vw;
    }
    
    .openLink {
        border: solid 0.08vw var(--main-black);
        width: 20vw;
        padding: 3vw 0vw;
        font-size: 2.27vw;
        border-radius: 1.14vw;
    }
}
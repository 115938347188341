.introSection {
    padding: 2.56vw var(--lr-margin-desktop) var(--tb-margin-desktop);
    display: flex;
    flex-direction: column;
    gap: 5.71vw;
}

.posterImageBox {
    width: 100%;
    height: 57.24vw;
    border-radius: 1.43vw;
    overflow: hidden;
}

.briefSection {
    display: flex;
    flex-direction: column;
    gap: 3.57vw;
}

.briefWrapper {
    display: flex;
    gap: 7.14vw;
}

.goalsHolder, .planHolder {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.briefText {
    font-size: 1.29vw;
    line-height: 1.6;
}

.briefGalleryGrid {
    display: grid;
    grid-template: repeat(1, 1fr) / repeat(3, 1fr);
    gap: 0vw 2.14vw;
}

.introImageBox, .outcomeImageBox {
    display: flex;
    flex-direction: column;
    gap: 1.14vw;
}

.introImage, .outcomeImage {
    width: 100%;
    border-radius: 0.71vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.introImage {
    height: 21.43vw;
}

.outcomeImage {
    height: 34.28vw;
}

.imageTag {
    font-size: var(--reg-p-20-desktop);
    font-weight: 600;
}

.outcomeSection {
    display: flex;
    flex-direction: column;
    gap: 3.57vw;
}

.finalDesignGrid {
    display: grid;
    grid-template: repeat(2, 1fr) / repeat(2, 1fr);
    gap: 3.21vw 2.14vw;
}

.moreWebsSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 992px) {
    .introSection {
        padding: 3.43vw var(--lr-margin-tablet) var(--tb-margin-tablet);
        gap: 6.85vw;
    }
    
    .posterImageBox {
        border-radius: 1.72vw;
    }
    
    .briefSection {
        gap: 4.28vw;
    }
    
    .briefWrapper {
        gap: 8.57vw;
    }
    
    .briefText {
        font-size: 1.55vw;
    }

    .briefGalleryGrid {
        gap: 0vw 2.57vw;
    }
    
    .introImageBox, .outcomeImageBox {
        gap: 1.37vw;
    }
    
    .introImage, .outcomeImage {
        border-radius: 0.85vw;
    }
    
    .imageTag {
        font-size: var(--reg-p-20-tablet);
    }
    
    .outcomeSection {
        gap: 4.28vw;
    }
    
    .finalDesignGrid {
        gap: 3.85vw 2.57vw;
    }
}

@media only screen and (max-width: 576px) {
    .introSection {
        padding: 7.55vw var(--lr-margin-mobile) var(--tb-margin-mobile);
        gap: 14.48vw;
    }
    
    .briefSection {
        gap: 18vw;
    }
    
    .briefWrapper {
        flex-direction: column;
        gap: 12.85vw;
    }

    .goalsHolder, .planHolder {
        width: 100%;
    }
    
    .briefText {
        font-size: 3.41vw;
        line-height: 1.65;
    }

    .briefGalleryGrid {
        grid-template: repeat(3, 1fr) / repeat(1, 1fr);
        gap: 8.65vw 0vw;
    }
    
    .introImageBox, .outcomeImageBox {
        gap: 3.01vw;
    }
    
    .introImage, .outcomeImage {
        border-radius: 1.87vw;
    }

    .introImage {
        height: 66vw;
    }
    
    .outcomeImage {
        height: 66vw;
    }
    
    .imageTag {
        font-size: var(--reg-p-20-mobile);
    }
    
    .outcomeSection {
        gap: 9.42vw;
    }

    .finalDesignGrid {
        grid-template: repeat(4, 1fr) / repeat(1, 1fr);
        gap: 8.65vw 5.65vw;
    }
}





/* STYLES FOR THE LINKS TO SECTIONS */
.galleryIntro {
    display: flex;
    flex-direction: column;
    gap: 1.43vw;
}

.sectionTag {
    font-weight: 300;
}

.sectionHeaderBox {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.sectionHeader {
    width: 32.14vw;
}

.linkGroupHolder {
    display: flex;
    gap: 1.79vw;
    align-items: center;
    margin-bottom: 0.1vw;
}

.linkHolder {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}

.linkText {
    font-weight: 600;
}

.linkUnderlineTrack {
    height: 0.09vw;
    background-color: #999999;
}

.linkUnderline {
    width: 0%;
    height: 100%;
    background-color: var(--main-black);
    transition: width 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.linkHolder:hover .linkUnderline {
    width: 100%;
}

@media only screen and (max-width: 992px) {
    .galleryIntro {
        gap: 1.72vw;
    }
    
    .sectionHeader {
        width: 38.57vw;
    }

    .linkGroupHolder {
        gap: 2.15vw;
        margin-bottom: 0.6vw;
    }

    .linkUnderlineTrack {
        height: 0.12vw;
    }
}

@media only screen and (max-width: 576px) {
    .galleryIntro {
        gap: 3.78vw;
    }

    .sectionHeaderBox {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 4.29vw;
    }
    
    .sectionHeader {
        width: 84.85vw;
    }

    .linkGroupHolder {
        gap: 4.73vw;
        margin-bottom: 0.26vw;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 100%;
    }

    .linkUnderlineTrack {
        height: 0.24vw;
    }
}
.projectsGallery{
    display: flex;
    flex-direction: column;
    gap: 5.71vw;
}

.curriculumSection {
    display: flex;
    flex-direction: column;
    gap: 14.29vw;
}

.processWrapper, .infoWrapper, .skillsWrapper {
    display: flex;
    flex-direction: column;
    gap: 2.14vw;
}

.toolsWrapper {
    padding-top: 2.14vw;
    border-top: dashed 0.07vw #22222250;
    display: flex;
    flex-wrap: wrap;
    gap: 1.43vw;
}

.cvLinkHolder {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 2.56vw;
    margin-top: -8.58vw;
}

@media only screen and (max-width: 992px) {
    .projectsGallery{
        gap: 6.85vw;
    }
    
    .curriculumSection {
        gap: 17.15vw;
    }
    
    .infoWrapper, .skillsWrapper {
        gap: 2.57vw;
    }

    .toolsWrapper {
        padding-top: 2.57vw;
        border-top: dashed 0.08vw #22222250;
        gap: 1.72vw;
    }

    .cvLinkHolder {
        padding-top: 3.07vw;
        margin-top: -10.3vw;
    }
}

@media only screen and (max-width: 576px) {
    .projectsGallery{
        gap: 15.07vw;
    }

    .curriculumSection {
        gap: 37.73vw;
    }

    .infoWrapper, .skillsWrapper {
        gap: 5.65vw;
    }

    .toolsWrapper {
        padding-top: 5.65vw;
        border-top: dashed 0.18vw #22222250;
        gap: 3.78vw;
    }

    .cvLinkHolder {
        padding-top: 6.75vw;
        margin-top: -22.66vw;
    }

}
.introSection {
    padding: 2.56vw var(--lr-margin-desktop) var(--tb-margin-desktop);
    display: flex;
    flex-direction: column;
    gap: 5.71vw;
}

.posterImageBox {
    width: 100%;
    height: 57.24vw;
    border-radius: 1.43vw;
    overflow: hidden;
}

.briefSection, .briefSectionReverse {
    display: flex;
    gap: 6.43vw;
}

.briefText {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.tagHolder {
    display: flex;
    gap: 1.07vw;
    margin-bottom: 1.79vw;
}

.briefTag {
    font-weight: 300;
}

.briefHeader {
    margin-bottom: 3.21vw;
}

.briefInfo {
    font-size: var(--reg-p-20-desktop);
    line-height: 1.7;
}

.briefImageBox {
    width: 50%;
    border-radius: 1.43vw;
    display: flex;
    justify-content: center;
    align-content: center;
    overflow: hidden;
}

.briefImage {
    height: 53.57vw;
    width: auto;
}

.highsSection {
    display: flex;
    flex-direction: column;
    gap: 3.57vw;
}

.highsIntroWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.headerBox {
    display: flex;
    flex-direction: column;
    gap: 1.43vw;
}

.sectionTag {
    font-weight: 300;
}

.sectionHeader {
    width: 32.14vw;
}

.highsCard {
    padding: 1.07vw;
    display: flex;
    gap: 1.07vw;
    background-color: #ffdcb0;
    border-radius: 0.71vw;
}

.highsCardIcon {
    width: 1.29vw;
    height: 1.29vw;
    margin-top: 0.3vw;
}

.highsCardText {
    font-weight: 300;
    line-height: 1.5;
    width: 28.5vw;
}

.gallerySection {
    display: flex;
    flex-direction: column;
    gap: 3.57vw;
}

.finalDesignGrid {
    display: grid;
    grid-template: repeat(2, 1fr) / repeat(2, 1fr);
    gap: 3.21vw 2.14vw;
}

.statsSection {
    display: flex;
    flex-direction: column;
    gap: 3.57vw;
}

.toolsWrapper {
    padding-top: 2.14vw;
    border-top: dashed 0.07vw #22222250;
    display: flex;
    flex-wrap: wrap;
    gap: 1.43vw;
}

.retrosWrapper {
    display: flex;
    gap: 7.14vw;
}

.winsHolder, .lessonsHolder {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.retrosText {
    font-size: 1.29vw;
    line-height: 1.6;
    border-top: dashed 0.07vw #22222250;
    padding-top: 2.14vw;
}

.moreWebsSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 992px) {
    .introSection {
        padding: 3.43vw var(--lr-margin-tablet) var(--tb-margin-tablet);
        gap: 6.85vw;
    }
    
    .posterImageBox {
        border-radius: 1.72vw;
    }

    .briefSection, .briefSectionReverse {
        gap: 7.72vw;
    }

    .tagHolder {
        gap: 1.28vw;
        margin-bottom: 2.15vw;
    }
    
    .briefHeader {
        margin-bottom: 3.85vw;
    }
    
    .briefInfo {
        font-size: var(--reg-p-20-tablet);
    }
    
    .briefImageBox {
        border-radius: 1.72vw;
    }
    
    .briefImage {
        height: 64.28vw;
    }

    .highsSection {
        gap: 4.28vw;
    }
    
    .headerBox {
        gap: 1.72vw;
    }
    
    .sectionHeader {
        width: 38.57vw;
    }
    
    .highsCard {
        padding: 1.28vw;
        gap: 1.28vw;
        border-radius: 0.85vw;
    }
    
    .highsCardIcon {
        width: 1.55vw;
        height: 1.55vw;
        margin-top: 0.36vw;
    }
    
    .highsCardText {
        width: 34.2vw;
    }

    .gallerySection {
        gap: 4.28vw;
    }

    .finalDesignGrid {
        gap: 3.85vw 2.57vw;
    }

    .statsSection {
        gap: 4.28vw;
    }

    .toolsWrapper {
        padding-top: 2.57vw;
        border-top: dashed 0.08vw #22222250;
        gap: 1.72vw;
    }

    .retrosWrapper {
        gap: 8.57vw;
    }
    
    .retrosText {
        font-size: 1.55vw;
        border-top: dashed 0.08vw #22222250;
        padding-top: 2.57vw;
    }
}

@media only screen and (max-width: 576px) {
    .introSection {
        padding: 7.55vw var(--lr-margin-mobile) var(--tb-margin-mobile);
        gap: 14.48vw;
    }
    
    .briefSection {
        flex-direction: column-reverse;
        gap: 15.44vw;
    }

    .briefSectionReverse {
        flex-direction: column;
        gap: 15.44vw;
    }

    .briefText {
        width: 95%;
    }

    .tagHolder {
        gap: 2.82vw;
        margin-bottom: 4.73vw;
    }
    
    .briefHeader {
        margin-bottom: 8.47vw;
    }
    
    .briefInfo {
        font-size: var(--reg-p-20-mobile);
    }
    
    .briefImageBox {
        width: auto;
        border-radius: 3.78vw;
    }
    
    .briefImage {
        height: 57.24vw;
        width: 100%;
    }

    .highsSection {
        gap: 9.42vw;
    }

    .highsIntroWrapper {
        flex-direction: column;
        align-items: flex-start;
        gap: 8.47vw;
    }
    
    .headerBox {
        gap: 3.78vw;
    }
    
    .sectionHeader {
        width: 84.85vw;
    }
    
    .highsCard {
        padding: 2.82vw;
        gap: 2.82vw;
        border-radius: 1.87vw;
    }
    
    .highsCardIcon {
        width: 3.41vw;
        height: 3.41vw;
        margin-top: 0.79vw;
    }
    
    .highsCardText {
        width: 70vw;
    }

    .gallerySection {
        gap: 9.42vw;
    }

    .finalDesignGrid {
        grid-template: repeat(4, 1fr) / repeat(1, 1fr);
        gap: 8.65vw 5.65vw;
    }

    .statsSection {
        gap: 9.42vw;
    }

    .toolsWrapper {
        padding-top: 5.65vw;
        border-top: dashed 0.18vw #22222250;
        gap: 3.78vw;
    }

    .retrosWrapper {
        flex-direction: column;
        gap: 9.42vw;
    }
    
    .winsHolder, .lessonsHolder {
        width: auto;
    }
    
    .retrosText {
        font-size: 3.41vw;
        border-top: dashed 0.18vw #22222250;
        padding-top: 4.71vw;
    }
}
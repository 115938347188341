.mainIntro {
    padding: 4.29vw var(--lr-margin-desktop) 1.79vw;
}

.galleryContainer {
    display: flex;
    flex-direction: column;
    gap: 3.57vw;
}

.galleryIntro {
    display: flex;
    flex-direction: column;
    gap: 1.43vw;
}

.sectionTag {
    font-weight: 300;
}

.sectionHeaderBox {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.sectionHeader {
    width: 32.14vw;
}

.linkGroupHolder {
    display: flex;
    gap: 1.79vw;
    align-items: center;
    margin-bottom: 0.1vw;
}

.backHomeSection {
    padding-top: 3.66vw;
}

@media only screen and (max-width: 992px) {
    .mainIntro {
        padding: 5.15vw var(--lr-margin-tablet) 2.15vw;
    }
    
    .galleryContainer {
        gap: 4.28vw;
    }
    
    .galleryIntro {
        gap: 1.72vw;
    }
    
    .sectionHeader {
        width: 38.57vw;
    }

    .linkGroupHolder {
        gap: 2.15vw;
        margin-bottom: 0.6vw;
    }

    .backHomeSection {
        padding-top: 3.99vw;
    }
}

@media only screen and (max-width: 576px) {
    .mainIntro {
        padding: 11.33vw var(--lr-margin-mobile) 4.73vw;
    }
    
    .galleryContainer {
        gap: 9.42vw;
    }
    
    .galleryIntro {
        gap: 3.78vw;
    }

    .sectionHeaderBox {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 4.29vw;
    }
    
    .sectionHeader {
        width: 84.85vw;
    }

    .linkGroupHolder {
        gap: 4.73vw;
        margin-bottom: 0.26vw;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 80%;
    }

    .backHomeSection {
        padding-top: 7.76vw;
    }
}




/* STYLES FOR THE LINKS TO SECTIONS */
.linkHolder {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}

.linkText {
    font-weight: 600;
}

.linkUnderlineTrack {
    height: 0.09vw;
    background-color: #999999;
}

.linkUnderline {
    width: 0%;
    height: 100%;
    background-color: var(--main-black);
    transition: width 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.linkHolder:hover .linkUnderline {
    width: 100%;
}

@media only screen and (max-width: 992px) {
    .linkUnderlineTrack {
        height: 0.12vw;
    }
}

@media only screen and (max-width: 576px) {
    .linkUnderlineTrack {
        height: 0.24vw;
    }
}
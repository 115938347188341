.DirectorWorkSection {
    padding: 4.29vw var(--lr-margin-desktop) var(--tb-margin-desktop);
    display: flex;
    flex-direction: column;
    gap: 3.57vw;
}

.DirectorWorkGalleryHolder {
    display: flex;
    flex-direction: column;
    gap: 5.71vw;
}

@media only screen and (max-width: 992px) {
    .DirectorWorkSection {
        padding: 5.15vw var(--lr-margin-tablet) var(--tb-margin-tablet);
        gap: 4.28vw;
    }
    
    .DirectorWorkGalleryHolder {
        gap: 6.85vw;
    }
}

@media only screen and (max-width: 576px) {
    .DirectorWorkSection {
        padding: 11.33vw var(--lr-margin-mobile) var(--tb-margin-mobile);
        gap: 9.42vw;
    }
    
    .DirectorWorkGalleryHolder {
        gap: 15.07vw;
    }
}